import React from "react"
import styled, { css } from "styled-components"
import FlipMove from "react-flip-move"
import Img from "gatsby-image"
import slugify from "@sindresorhus/slugify"
import { Link } from "gatsby"
import RGA from "react-ga"
import "../styles/variables.css"
import ScrollAnimation from 'react-animate-on-scroll'

const OrgWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 133px;
  @media screen and (max-width: 680px) {
    align-items: flex-start;
    // padding-top: 48px;

    .title,
    .details {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .title {
    font-size: var(--header-2);
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Magnificent', serif;
    // line-height: 72px;
    color: var(--yellow);
    text-shadow: 0px 2px 0px #201A2C;
    margin: 3.6em 0 0.6em;
    text-align: center;
    width: 100%;
  }

  .details {
    font-size: 18px;
    line-height: 27px;
    color: var(--white);
    font-family: 'Biko', sans-serif;
    text-align: center;
    max-width: 80%;
    margin: auto;
  }

  @media screen and (max-width: 500px) {
    /* padding-left: 45px; */

    .details {
      font-size: 12.8px;
    }
  }
`

const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 1050px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 48px;
  width: 80%;

  @media screen and (max-width: 900px){
    width: 90%;
  }

  @media screen and (max-width: 500px) {
    flex-direction: row;
    position: fixed;
    background: white;
    flex-wrap: nowrap;
    border-top: #f1f1f1 1px solid;
    background-color: var(--magenta-300);
    justify-content: flex-start;
    overflow-x: scroll;
    z-index: 1000;
    bottom: 0%;
    padding: 8px;
    flex-shrink: 0;
    box-shadow: 0 2px 4px 0 rgba(215, 215, 215, 0.5);
    transition: transform 0.6s ease-in-out;
    width: 100%;

    ${p =>
      !p.shouldShow &&
      css`
        transform: translateY(100%);
      `}
  }
`

const FilterButton = styled.button`
  /* transparent */
  background-color: transparent;
  border: 1.5px solid var(--white);
  color: var(--white);
  border-radius: 4px;
  box-sizing: border-box;
  margin: 8px;
  padding: 0.6em 1.5em;
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  font-family: 'Biko', sans-serif;
  font-weight: 400 !important;


  @media screen and (max-width: 500px) {
    white-space: nowrap;
    margin: 4px;
  }
  &:hover {
    box-shadow: rgba(215, 215, 215, 0.5) 0 2px 4px 0;
    transform: translateY(-0.5px);
  }

  ${props =>
    props.active &&
    css`
      background-color: #e8f7ff;
      border-color: #e8f7ff;
      color: var(--black);
      font-weight: 700;
    `};
`

const CardWrapper = styled(FlipMove)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1500px;
  padding: 48px;
  min-height: 400px;
  font-family: 'Biko', sans-serif;

  @media all and (max-width: 950px) {
    padding: 5px;
  }

  @media all and (max-width: 500px) {
    padding-top: 15px;
  }

  .top {
    display: flex;
  }

  .card {
    background-color: var(--white);
    color: var(--black);
    border-radius: 4px;
    box-shadow: 8px 8px 10px var(--indigo-100);
    box-sizing: border-box;
    flex: 1 0 380px;
    height: 144px;
    margin: 10px;
    max-width: 380px;
    padding: 15px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      box-shadow: rgba(194, 194, 194, 0.5) 0 2px 6px 0;
      opacity: 0.5;
      transition: all 0.2s ease-in-out;
    }
  }

  @media all and (max-width: 380px) {
    .card {
      flex: 1 0 290px;
      height: auto;
    }
  }

  @media all and (max-width: 790px) {
    .card {
      flex: 1 0 240px;
      height: auto;
    }
  }

  @media all and (max-width: 400px) {
    .card {
      min-width: unset;
    }
  }

  .card-avatar {
    box-sizing: border-box;
    float: left;
    margin-right: 10px;
    width: 40px;
  }

  .organization-name {
    font-size: 18px;
    margin: 0px;
    color: var(--purple-300);
    font-weight: 700;
  }

  .organization-description {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0px;
    font-weight: 400;
    color: var(--purple-100);
    text-decoration: none;
  }

  .card-tags {
    color: #a7a4b4;
    font-size: 12px;
    margin: 0px;
    text-align: left;
    padding-top: 4px;
  }

  .card-hr {
    background: #706583;
    height: 1px;
    border: none;
    margin-top: 15px;
  }
`

const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export default function Organizations({ cards, pages }) {
  const categories = [
    "all",
    "adventure",
    "arts",
    "business",
    "community development",
    "culture",
    "design",
    "education",
    "environment",
    "home org",
    "international",
    "language",
    "lifestyle",
    "literature",
    "music",
    "spiritual",
    "technology",
  ]

  const [filter, setFilter] = React.useState("all")
  const [showFilter, setShowFilter] = React.useState(true)

  const orgRef = React.useRef()
  React.useEffect(() => {
    const callback = function(entries, observer) {
      if (entries[0].isIntersecting) {
        setShowFilter(true)
      } else {
        setShowFilter(false)
      }
    }
    const observer = new IntersectionObserver(callback, {
      rootMargin: "-100px",
    })
    observer.observe(orgRef.current)

    return () => observer.unobserve(orgRef.current)
  }, [])

  return (
    <>
      <OrgWrapper ref={orgRef} id="organizations"> 
        {/* <ScrollAnimation animateIn="fadeUp" animateOnce="true"> */}
          <h1 className="title">The Organizations of Lions</h1>
          {/* </ScrollAnimation> */}
        <ScrollAnimation animateIn="fadeUp" delay="200" animateOnce="true">
          <p className="details">
          Join passionate communities of like-minded individuals, forge meaningful connections, and grow stronger together along the way. Discover which of the LIONS organizations best suits your interest.
          </p>
        </ScrollAnimation>
        <FilterWrapper shouldShow={showFilter}>
          {categories.map(el => {
            return (
              <FilterButton
                active={filter === el}
                onClick={() => {
                  setFilter(el)
                  RGA.event({
                    category: `${capitalize(el)} Filter`,
                    action: "filter",
                  })
                }}
                key={el}
              >
                {capitalize(el)}
              </FilterButton>
            )
          })}
        </FilterWrapper>
        <CardWrapper typeName="section">
          {cards.edges
            .sort((a, b) => {
              const aName = a.node.data.org__name.text.toLowerCase()
              const bName = b.node.data.org__name.text.toLowerCase()
              if (aName < bName) {
                return -1
              }
              if (aName > bName) {
                return 1
              }
              return 0
            })
            .filter(el => {
              const {
                node: { data },
              } = el
              const categories = data.org_category_list.map(
                el => el.org_category
              )
              if (filter === "all") {
                return el
              }
              if (categories.includes(filter)) {
                return el
              }

              return false
            })
            .map((el, i) => {
              const {
                node: { data },
              } = el
              return (
                <Link
                  key={data.org__name.text}
                  className="card"
                  to={`/${slugify(data.org__name.text)}/`}
                  onClick={() => {
                    RGA.event({
                      category: data.org__name.text,
                      action: "card-click",
                    })
                  }}
                >
                  <div className="top">
                    <Img
                      fixed={data.org_logo.localFile.childImageSharp.fixed}
                      className="card-avatar"
                      style={{ width: "40px" }}
                    />
                    <header className="card-header">
                      <h1 className="organization-name">
                        {data.org__name.text}
                      </h1>
                      <h1 className="organization-description">
                        {data.org_description.text}
                      </h1>
                    </header>
                  </div>

                  <hr className="card-hr" />
                  <p className="card-tags">{data.org_tags.text}</p>
                </Link>
              )
            })}
        </CardWrapper>
      </OrgWrapper>
    </>
  )
}
