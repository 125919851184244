import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// components
import Navigation from "../components/Nav"
import Organizations from "../components/Organizations"
import Footer from "../components/Footer"

//export
const Wrapper = styled.div`
  background: linear-gradient(180deg, #7D2047 0%, #090836 80%);;
`
export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Join LIONS RecWeek this September 5 - 7 at the Colayco Pavillion"></SEO>
      <Wrapper>
        <Navigation logo={data.logo} smallLogo={data.smallLogo} />
        <Organizations cards={data.cards} pages={data.pages}></Organizations>
        <Footer data={data} margin="-30%" style={{ zIndex: 999 }} />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query OrganizationImages {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 112) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    smallLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    group: file(relativePath: { eq: "group_pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    big: file(relativePath: { eq: "bigpic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cards: allPrismicOrgCard {
      edges {
        node {
          data {
            org__name {
              text
            }
            org_description {
              text
            }
            org_logo {
              localFile {
                absolutePath
                childImageSharp {
                  fixed(quality: 100, width: 40) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            org_tags {
              text
            }
            org_category_list {
              org_category
            }
          }
        }
      }
    }
  }
`
